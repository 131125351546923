<template>
    <div>
        Modifiche in sospeso
    </div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     data() {
         return {
         };
     },
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Comitati", route: "/committee/modifiche-in-sospeso" },
             { title: "Modifiche in sospeso" }
         ]);
     }
 };
</script>
